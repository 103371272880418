var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"command-limit-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.descriptionKey) ? _vm.$t(_vm.module.model.descriptionKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"my-5"},[_c('switch-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'check_native_limits',
          'disabled': _vm.disabled
        }
      }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'demote_admins',
          'disabled': _vm.disabled
        }
      }}})],1),(_vm.module.state.limits.length > 5)?_c('div',{staticClass:"text-right"},[_c('a-button',{attrs:{"type":"primary","icon":"plus","disabled":_vm.disabled},on:{"click":_vm.handleCreateClick}},[_vm._v(" "+_vm._s(_vm.$t('add_command_limit_button_title'))+" ")])],1):_vm._e(),_c('moderation-limit-list',{staticClass:"mt-5",attrs:{"limits-list":_vm.module.state.limits},on:{"on-add-limit":_vm.handleCreateClick,"on-delete-limit":_vm.module.deleteLimit,"on-limit-select":_vm.setLimitToEdit}}),(_vm.module.state.selectedLimit)?_c('moderation-limit-item',{attrs:{"action":_vm.action,"modal-active":_vm.isModalOpen,"is-whitelist-selected":_vm.module.isWhitelistSelected,"is-limit-all-permission":_vm.module.isLimitAllPermission},on:{"update:modalActive":function($event){_vm.isModalOpen=$event},"update:modal-active":function($event){_vm.isModalOpen=$event},"on-cancel":function($event){_vm.module.state.selectedLimit = null},"on-ok-click":_vm.handleOkClick},model:{value:(_vm.module.state.selectedLimit),callback:function ($$v) {_vm.$set(_vm.module.state, "selectedLimit", $$v)},expression:"module.state.selectedLimit"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }